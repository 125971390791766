import React from 'react'

// Libraries
import { Outlet } from 'react-router-dom';

import Footer from './components/Footer';

const SparktrolPage = (props) => {
  return (
    <div style={{
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      ...props.style
    }}>

      {/* Section Start */}
      <a
        className="bg-cover bg-darkslateblue block bg-center bg-no-repeat relative xs:bg-contain xs:h-[100px] h-[220px]"
        href="/"
        style={{ backgroundImage: `url(/assets/img/sparktrol.png)`, backgroundSize: 'contain' }}></a>
      {/* Section Start */}

      <Outlet />

      <Footer />
    </div>
  )
}

export default SparktrolPage