import React from 'react'

// Libraries
import { Row, Col, Container } from 'react-bootstrap';

// Components
import { fadeIn } from '../../Functions/GlobalAnimations';
import Brands from './components/Brands';

import { brands } from './data';
import FooterStyle01 from '../../Components/Footers/FooterStyle01';
import { AnimatePresence, m } from "framer-motion";
import ContactForm from './ContactForm';


const HomePage = (props) => {
  return (
    <div style={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      ...props.style
    }}>
      {/* Section Start */}
      <m.section className="dark pt-[40px] pb-[40px] lg:py-[90px] md:py-[75px] sm:py-[50px] flex flex-1 items-center">
        <Container>
          <Row>
            <Col lg={12} md={11} xs={12}>
              <Brands grid="row row-cols-1 row-cols-lg-4 row-cols-xs-2 row-cols-sm-2 justify-center gap-y-[15px]" theme='service-style-06' data={brands} animation={fadeIn} />
            </Col>
          </Row>
        </Container>
      </m.section>
      {/* Section End */}

      <FooterStyle01 theme="dark" className="text-slateblue bg-[#262b35]" />
    </div >
  )
}

export default HomePage