import React, { useEffect } from 'react'

// Libraries
import { Col, Container, Row } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'

// Components
import { rotateInLeft } from '../../Functions/GlobalAnimations';

// Data
import { brands } from './data';
import RotateBox from '../../Components/RotateBox/RotateBox';

import "../../Assets/scss/components/_products_sparktrol.scss"
import { PropTypes } from "prop-types";
import { useState } from 'react';
import ContactForm from './ContactForm';

const ProductsPage = (props) => {

  const { company: slug } = useParams()

  const [selectedBrand, setSelectedBrand] = useState([])
  const [products, setProducts] = useState([])

  useEffect(() => {
    const brand = brands.filter((item) => item.slug === slug)[0]
    setSelectedBrand(() => brand)

    setProducts(brand.products)
  }, [slug])

  return (
    <div className='products-sparktrol' style={props.style}>
      <section className="bg-darkgray py-[25px] border-t">
        <Container className=''>
          <Row className="items-center justify-center mb-0 ">
            <Col xl={8} lg={6} className="breadcrumb justify-start  text-xs font-serif md:mt-[10px] mb-0 md:justify-center">
              <ul className="xs-text-center ">
                <li><Link aria-label="breadcrumb" to="/" className="hover:text-white text-xlg">Home</Link></li>
                <li><span className="hover:text-white text-xlg">{selectedBrand.title}</span></li>
              </ul>
            </Col>
            <Col xl={4} lg={6} className="breadcrumb justify-end text-xs font-serif md:mt-[10px] mb-0 md:justify-center">
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container className="h-full relative pt-[40px]">
          <Row className="row items-stretch justify-center ">
            <Col xl={6} lg={7} md={8} xs={12} className="text-center flex justify-center flex-col">
              <div className={`img-wrapper ${selectedBrand.bgColor ? selectedBrand.bgColor : "bg-[#262b35]"}`}>
                <img height={150} width={180} className="w-full max-w-full align-middle" src={selectedBrand.img} alt={selectedBrand.title} title={selectedBrand.title} />
              </div>
              <p>
                {selectedBrand.description}
              </p>
            </Col>
          </Row>

        </Container>
      </section>

      {/* Overlap Section Start */}
      <section>
        <Container>
          <Row className="row items-stretch justify-center py-[30px]">
            <Col xl={6} lg={7} md={8} xs={12} className="text-center flex justify-center flex-col">
              <h3 className="text-darkgray mb-[0px] font-serif font-medium -tracking-[1px] xs:mb-[0px]">Products</h3>
            </Col>
          </Row>
          <Row className="justify-center lg:mt-0 md:mt-0">
            <RotateBox grid="row-cols-1 row-cols-md-2 row-cols-lg-3 gap-y-10 justify-center" company={slug} data={products} />
          </Row>
        </Container>
      </section>
      {/* Overlap Section End */}


      <ContactForm />

    </div >
  )
}

ProductsPage.propTypes = {
  companyName: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.exact({
      title: PropTypes.string,
      description: PropTypes.string,
      img: PropTypes.string,
      subtitle: PropTypes.string,
      icon: PropTypes.string,
      btnLink: PropTypes.string,
      btnTitle: PropTypes.string,
      content: PropTypes.string,
    })
  ),
}

export default ProductsPage