export const brands = [
  {
    img: "/assets/img/fgv-valvole.png",
    link: "/produtos",
    title: "Special Valves",
    slug: "fgv",
    description:
      `FGV specialises in designing and manufacturing a wide range of customised high performance valves either manually operated or actuated and for any kind of fluid used in the Oil, Gas, Water, Steam, Petrochemical and Power Generating Industry. Within their own facility, they are currently capable of designing, assembling and testing in accordance with almost all the recognised standards and Customer specifications. FG Valvole range of production includes the use of such unique materials as Super Duplex, Duplex, 6Mo, Monel, Incoloy 825, Inconel and many other alloys. Main production includes Ball, Axial Flow, Gate, Globe and Check Valves in different executions, materials and ratings.`,
    products: [
      {
        "id": "fgv-products-ball-valves-0",
        "title": "Trunnion Side Entry Ball Valve",
        "img": "img/products/fgv/products-ball-valves-trunnion-side-entry.png",
        "subtitle": "Ball",
        "icon": "line-icon-Flick",
        "btnLink": "produtos/detalhe",
        "btnTitle": "See Details",
        "content": "Trunnion Side Entry Ball Valve",
        "description": "Trunnion Side Entry Ball Valve"
      },
      {
        "id": "fgv-products-ball-valves-1",
        "title": "Floating Side Entry Ball Valve",
        "img": "img/products/fgv/products-ball-valves-floating-side-entry.png",
        "subtitle": "Ball",
        "icon": "line-icon-Flick",
        "btnLink": "produtos/detalhe",
        "btnTitle": "See Details",
        "content": "Floating Side Entry Ball Valve",
        "description": "Floating Side Entry Ball Valve"
      },
      {
        "id": "fgv-products-ball-valves-2",
        "title": "Top Entry Ball Valve",
        "img": "img/products/fgv/products-ball-valves-top-entry.png",
        "subtitle": "Ball",
        "icon": "line-icon-Flick",
        "btnLink": "produtos/detalhe",
        "btnTitle": "See Details",
        "content": "Top Entry Ball Valve",
        "description": "Top Entry Ball Valve"
      },
      {
        "id": "fgv-products-ball-valves-3",
        "title": "Fully Welded Body Ball Valve",
        "img": "img/products/fgv/products-ball-valves-fully-welded-body.png",
        "subtitle": "Ball",
        "icon": "line-icon-Flick",
        "btnLink": "produtos/detalhe",
        "btnTitle": "See Details",
        "content": "Fully Welded Body Ball Valve",
        "description": "Fully Welded Body Ball Valve"
      },
      {
        "id": "fgv-products-ball-valves-4",
        "title": "Silent Segment Ball Valve",
        "img": "img/products/fgv/products-ball-valves-silent-segment.png",
        "subtitle": "Ball",
        "icon": "line-icon-Flick",
        "btnLink": "produtos/detalhe",
        "btnTitle": "See Details",
        "content": "Silent Segment Ball Valve",
        "description": "Silent Segment Ball Valve"
      },
      {
        "id": "fgv-products-axial-flow-valves-0",
        "title": "Axial flow valves",
        "img": "img/products/fgv/products-axial-flow-valves.png",
        "subtitle": "Axial Flow",
        "icon": "line-icon-Flick",
        "btnLink": "produtos/detalhe",
        "btnTitle": "See Details",
        "content": "Axial flow valves",
        "description": "Axial flow valves"
      },
      {
        "id": "fgv-gate-globe-and-check-valves-0",
        "title": "Pressure Seal Gate Valve",
        "img": "img/products/fgv/gate-globe-and-check-valves-pressure-seal-gate.png",
        "subtitle": "Gate",
        "icon": "line-icon-Flick",
        "btnLink": "produtos/detalhe",
        "btnTitle": "See Details",
        "content": "Pressure Seal Gate Valve",
        "description": "Pressure Seal Gate Valve"
      },
      {
        "id": "fgv-gate-globe-and-check-valves-1",
        "title": "Wedge Gate Bolted Bonnet Valve",
        "img": "img/products/fgv/gate-globe-and-check-valves-wedge-gate-bolted-bonnet.png",
        "subtitle": "Gate",
        "icon": "line-icon-Flick",
        "btnLink": "produtos/detalhe",
        "btnTitle": "See Details",
        "content": "Wedge Gate Bolted Bonnet Valve",
        "description": "Wedge Gate Bolted Bonnet Valve"
      },
      {
        "id": "fgv-gate-globe-and-check-valves-2",
        "title": "Through Conduit Slab Gate Valve",
        "img": "img/products/fgv/gate-globe-and-check-valves-through-conduit-slab.png",
        "subtitle": "Gate",
        "icon": "line-icon-Flick",
        "btnLink": "produtos/detalhe",
        "btnTitle": "See Details",
        "content": "Through Conduit Slab Gate Valve",
        "description": "Through Conduit Slab Gate Valve"
      },
      {
        "id": "fgv-gate-globe-and-check-valves-3",
        "title": "Through Conduit Double Expanding Gate Valve",
        "img": "img/products/fgv/gate-globe-and-check-valves-through-conduit-double-expanding.png",
        "subtitle": "Gate",
        "icon": "line-icon-Flick",
        "btnLink": "produtos/detalhe",
        "btnTitle": "See Details",
        "content": "Through Conduit Double Expanding Gate Valve",
        "description": "Through Conduit Double Expanding Gate Valve"
      },
      {
        "id": "fgv-products-check-valves-0",
        "title": "Nozzle Check Valve",
        "img": "img/products/fgv/products-check-valves-nozzle.png",
        "subtitle": "Check",
        "icon": "line-icon-Flick",
        "btnLink": "produtos/detalhe",
        "btnTitle": "See Details",
        "content": "Nozzle Check Valve",
        "description": "Nozzle Check Valve"
      },
      {
        "id": "fgv-products-check-valves-1",
        "title": "Swing Check Valve",
        "img": "img/products/fgv/products-check-valves-swing.png",
        "subtitle": "Check",
        "icon": "line-icon-Flick",
        "btnLink": "produtos/detalhe",
        "btnTitle": "See Details",
        "content": "Swing Check Valve",
        "description": "Swing Check Valve"
      },
      {
        "id": "fgv-products-check-valves-2",
        "title": "Dual Plate/Tilting Disc Check Valve",
        "img": "img/products/fgv/products-check-valves-dual-platetilting.png",
        "subtitle": "Check",
        "icon": "line-icon-Flick",
        "btnLink": "produtos/detalhe",
        "btnTitle": "See Details",
        "content": "Dual Plate/Tilting Disc Check Valve",
        "description": "Dual Plate/Tilting Disc Check Valve"
      },
      {
        "id": "fgv-products-globe-valves-0",
        "title": "Pressure Seal Globe Valve",
        "img": "img/products/fgv/products-globe-valves-pressure-seal.png",
        "subtitle": "Globe",
        "icon": "line-icon-Flick",
        "btnLink": "produtos/detalhe",
        "btnTitle": "See Details",
        "content": "Pressure Seal Globe Valve",
        "description": "Pressure Seal Globe Valve"
      },
      {
        "id": "fgv-products-globe-valves-1",
        "title": "Globe Bolted Bonnet Valve",
        "img": "img/products/fgv/products-globe-valves-globe-bolted-bonnet.png",
        "subtitle": "Globe",
        "icon": "line-icon-Flick",
        "btnLink": "produtos/detalhe",
        "btnTitle": "See Details",
        "content": "Globe Bolted Bonnet Valve",
        "description": "Globe Bolted Bonnet Valve"
      },
      {
        "id": "fgv-products-double-block-bleed-valves-0",
        "title": "Modular Double Block and Bleed Valve",
        "img": "img/products/fgv/products-double-block-bleed-valves.png",
        "subtitle": "Modular Double Block and Bleed",
        "icon": "line-icon-Flick",
        "btnLink": "produtos/detalhe",
        "btnTitle": "See Details",
        "content": "Modular Double Block and Bleed Valve",
        "description": "Modular Double Block and Bleed Valve"
      },
      {
        "id": "fgv-products-pig-valves-0",
        "title": "Pig Valves",
        "img": "img/products/fgv/products-pig-valves.png",
        "subtitle": "Pig",
        "icon": "line-icon-Flick",
        "btnLink": "produtos/detalhe",
        "btnTitle": "See Details",
        "content": "Pig Valves",
        "description": "Pig Valves"
      },
      {
        "id": "fgv-products-multiport-valves-0",
        "title": "Multiport Valves",
        "img": "img/products/fgv/products-multiport-valves.png",
        "subtitle": "Multiport",
        "icon": "line-icon-Flick",
        "btnLink": "produtos/detalhe",
        "btnTitle": "See Details",
        "content": "Multiport Valves",
        "description": "Multiport Valves"
      },
      {
        "id": "fgv-products-choke-valves-0",
        "title": "Choke Valves",
        "img": "img/products/fgv/products-choke-valves.png",
        "subtitle": "Choke",
        "icon": "line-icon-Flick",
        "btnLink": "produtos/detalhe",
        "btnTitle": "See Details",
        "content": "Choke Valves",
        "description": "Choke Valves"
      },
      {
        "id": "fgv-products-butterfly-valves-0",
        "title": "Butterfly Valves",
        "img": "img/products/fgv/butterfly-valves.png",
        "subtitle": "Butterfly",
        "icon": "line-icon-Flick",
        "btnLink": "produtos/detalhe", "btnTitle": "See Details", "content": "Butterfly Valves", "description": "Butterfly Valves"
      }
    ],
  },
  {
    img: "/assets/img/metroval.png",
    bgColor: "#fff",
    borderColor: "#262b35",
    title: "Metrology",
    slug: "metroval",
    description:
      "The Metroval history began in 1987 when the company Tecnobrás S.A., water meters manufacturer, won a contract for technology transfer, for manufacturing positive displacement meters, with Bopp & Reuther Messtechnik GmbH, from Germany, known worldwide for its long tradition in the instrumentation and control field. This contract was transferred to Metroval in 1988, date of the company founding. In 1991, another technology transfer contract, with the company Rheonik Messgerate GmbH - also from Germany, granted the mass meters manufacturing by Coriolis effect, in a pioneering move in Brazil. With a prominent position in the flow measurement domesc market, the years followed with numerous accomplishments: One of the first domesc companies to obtain in 1994 an ISO 9000 certification; Inmetro Accreditation in 2004, of its flow gage calibration laboratories, integrang RBC Brazilian Calibration Laboratories Network; Conquest of the largest flow measurement contract of national history: the adequacy of four Petrobras assets in the Campos Basin, totaling 14 oil platforms; Opening of a branch office in Macaé (RJ), in order to provide services and support to Oil and Gas companies. With almost 3 decades of experience, today Metroval is proud to count more than 30,000 meters sold to over 6,000 customers. A genuinely Brazilian company and national leader in measurement technology, Its commitment to quality and technology has placed it as the only company in Lan America to completely dominate the production cycle of flow measurement systems, enabling the continued development of fluid control solutions.",
    products: [
      {
        id: "metroval-1",
        title: "Orifice Fitting Dual Chamber",
        img: "https://via.placeholder.com/400x400",
        subtitle: "",
        icon: "line-icon-Flick",
        btnLink: "/produtos/detalhe",
        btnTitle: "See details",
        description:
          "Metroval offers different types of orifice carriers, including the Single Chamber, the Double Chamber that does not require interruption of the process for the replacement of the plates, in addition to the double block and bleed option that offers extra safety during this operation, meeting international standards, AGA, API and ISO. The manufacture of Metroval orifice fittings is 100% national, fulfilling the requirements of international standards for the product, as: ISO 5167, AGA 3, ASME B16.36, ASME B16.5. Materials according to ASTM, with certificate. The usual materials are:Body: Carbon steel (A216 WCB, A216 WCC, A352 LCC), Stainless Steel (A351 CF8M, A351 CF3M), Duplex (A995 4A, 5A, 6A) Internal components: stainless steel 316. When specified fulfilling the specifications of NACE recommendations. Special materials available under request. The Double Block orifice fitting was developed for maximum operational safety, applicable where there is need for redundancy in the sealing for the orifice plate removal without the depressurizing the pipeline. The Metroval DBB is modular, that is, the module (Intermediate chamber) can be installed in standard double chamber Metroval orifice fitting.",
      },
      {
        id: "metroval-2",
        title: "Metrocone high quality liquid and gas flow measurement",
        img: "https://via.placeholder.com/400x400",
        subtitle: "Cone Meters",
        icon: "line-icon-Flick",
        btnLink: "/produtos/detalhe",
        btnTitle: "See details",
        description:
          `FLUIDS 
          Oil and its derivates;
          Water/oil emulsions;
          Fresh and injection water;
          Dry and humid natural gas;
          Vapor;
          Others upon request. 
          APPLICATIONS 
          Gas and water injection into wells; 
          Burners and furnaces;
          Boilers;
          Compressors and generators; 
          Ideal for tax measurement, gas custody appropriation and transfer 
          
          `,
      },
      {
        id: "metroval-3",
        title: "AUTOMATIC SAMPLING SYSTEM",
        img: "https://via.placeholder.com/400x400",
        subtitle: "",
        icon: "line-icon-Flick",
        btnLink: "/produtos/detalhe",
        btnTitle: "See details",
        description:
          "The Metroval sampling systems are designed for custody transfer, fiscal, allocation, well test, test separator and other purposes that require representative samples of liquids for composition and quality measurement.They can be applied on offshore platforms, FPSOs including offloading lines, terminals and pipelines in accordance with the sampling standards ISO 3171, ASTM D 4177, API 8.2 and IP 6.2. The sampler probes are supplied with a performance warranty to work under extreme process conditions and guarantees long Mean Time Between Failure (MTBF). These characteristics are guaranteed due to the use of very high resistance sealing materials (PCTFE and PEEK). In general, the Metroval automatic sampler probes work well under conditions that no other probes can perform.",
      },
      {
        id: "metroval-4",
        title: "Chemicals Products Injection System",
        img: "https://via.placeholder.com/400x400",
        subtitle: "",
        icon: "line-icon-Flick",
        btnLink: "/produtos/detalhe",
        btnTitle: "See details",
        description:
          `Metroval's chemical injection system is based on effective flow control and measurement of the quantity of injected liquids. Its biggest difference is automatic flow control through a valve/flow meter set. This combination allows absolute control of the dosing process.
          Automatic and accurate chemicals control for oil production 
          Metroval dosing and additive systems are the ideal solution for dosing chemical additives or chemical compounds that require accurate dosing of different liquid components. 
          In this Metroval systems, each compound is individually measured by a high accuracy flow meter and its dosage is controlled by a control valve, in order to guarantee the correct dosing established by the customer's specification. 
          The system can operate with an existing supervisory, or it can be provided with an interface that allows the programming and monitoring of variables directly by the user through a human-machine interface (HMI). 
          These systems can operate continuously or in batches, designed according to the customer's specifications to meet the most varied process characteristics, such as flow, pressure, temperature and chemical compatibility. 
          Benefits 
          Measured and controlled fluids individually – greater accuracy 
          Modular construction, rack mounting 
          Usable in classified area 
          High pressures (up to 15,000 PSI) and low flow rates (up to 0.5 l/h) 
          Applications 
          Dosing, Additivation and Mixing of multiple products 
          Process with continuous operation or per batch
          Chemical and mercaptan injection
          `,
      },
      {
        id: "metroval-5",
        title: "Metroval Fuel Consumption Monitor - MCCM",
        img: "https://via.placeholder.com/400x400",
        subtitle: "",
        icon: "line-icon-Flick",
        btnLink: "/produtos/detalhe",
        btnTitle: "See details",
        description:
          "The MCCM is a volumetric fuel measurement system basically composed of an Electronic Unit that manages, processes and transmits data from two or more Flow Meters associated with it. It was developed with the aim of providing fuel managers, in various industrial segments, with all the data they need so that they can obtain maximum energy performance from their equipment powered by diesel engines.",
      },
      {
        id: "metroval-6",
        title: "Measurement Systems Embedded in TRR Tank Trucks",
        img: "https://via.placeholder.com/400x400",
        subtitle: "",
        icon: "line-icon-Flick",
        btnLink: "/produtos/detalhe",
        btnTitle: "See details",
        description:
          "The product's operating principle is to measure and control the volume delivered for each shipment, always aiming for agility, ease and precise and efficient control of these supplies. The robust tanker truck supply system sold by Metroval has a meter with Inmetro model approval for custody transfer. Furthermore, the system has a robust yet compact structure, simple operation and high precision, with a maximum error of 0.2% in the measured range. The product also has a supply nozzle detection system, control and online GPRS mapping.",
      },
      {
        id: "metroval-7",
        title: "Fluid Supply Systems for Automotive Assembly Lines",
        img: "https://via.placeholder.com/400x400",
        subtitle: "",
        icon: "line-icon-Flick",
        btnLink: "/produtos/detalhe",
        btnTitle: "See details",
        description:
          "Customized by Metroval, these supply systems use the same principle as oval gear-type positive displacement volumetric meters. The equipment has high measurement performance, with a maximum error of 0.2% of the measured value in the range in which it is calibrated, which guarantees supplies with repetitive volumes and the lowest possible error rate. The robust and easy-to-operate system consists of a filter, volumetric flow meter, piping, dosing valves and barcode reader, among other equipment to guarantee the best performance.",
      },
      {
        id: "metroval-8",
        title: "Fuel Unloading Systems",
        img: "https://via.placeholder.com/400x400",
        subtitle: "",
        icon: "line-icon-Flick",
        btnLink: "/produtos/detalhe",
        btnTitle: "See details",
        description:
          "Unloading systems are used to receive fuel and liquid raw materials. Equipped with a system for eliminating gases from unloading the tank, this system ensures that only the volume of liquid is measured, thus guaranteeing the reliability of the process, in addition to measuring density and °INPM and correcting the volume at a temperature of 20° C. Have model approval for custody transfer.",
      },
      {
        id: "metroval-9",
        title: "Brix Measurement System in Must",
        img: "https://via.placeholder.com/400x400",
        subtitle: "",
        icon: "line-icon-Flick",
        btnLink: "/produtos/detalhe",
        btnTitle: "See details",
        description:
          "Applied in processes carried out in the sugar and ethanol industry, the equipment promotes the measurement and control of brix in the must, becoming essential for obtaining maximum efficiency in ethanol production. The system stabilizes the °Brix during must formation and offers repetition in the fermentation process, which generates stable supply in the distillery and optimizes production capacity throughout the operation. The system has an automatic cleaning process, in order to avoid crusting and clogging caused by sugar after cooling.",
      },
      {
        id: "metroval-10",
        title: "Production Measurement Systems",
        img: "https://via.placeholder.com/400x400",
        subtitle: "",
        icon: "line-icon-Flick",
        btnLink: "/produtos/detalhe",
        btnTitle: "See details",
        description:
          "Developed to effectively control the ethanol produced and assist in the management of plant stocks, the equipment consists of systems for production control, tank inventory and loading control. Considering that the volume of the fluid can vary according to temperatures, the systems developed by Metroval work to measure operational conditions and correct the reference conditions, which are 20°C.",
      },
      {
        id: "metroval-11",
        title: "Fuel Charging Systems",
        img: "https://via.placeholder.com/400x400",
        subtitle: "",
        icon: "line-icon-Flick",
        btnLink: "/produtos/detalhe",
        btnTitle: "See details",
        description:
          "Fully automated modular systems for fuel loading bases. They meet metrological requirements of Inmetro and ABNT 5992/2008. They carry out temperature, flow and density measurements, control the flow and report the measured variables, correcting the volume loaded at a temperature of 20ºC.",
      },
      {
        id: "metroval-12",
        title: "Flow Measurement System for Deprimogeneous Elements",
        img: "https://via.placeholder.com/400x400",
        subtitle: "",
        icon: "line-icon-Flick",
        btnLink: "/produtos/detalhe",
        btnTitle: "See details",
        description:
          "The oil and gas industry is one of those that uses the product most, due to the principle of flow measurement by differential pressure, especially in the measurement of natural gas, due to its low cost, simplicity of installation and high accuracy, always in compliance with the recognized standards (AGA or ISO) for this type of application. The main and most traditional representative of this category of measuring elements is the orifice plate, which, like the other elements by this principle, generates a differential pressure through the measuring element proportional to the flow rate. The other representatives of this class of meters are Metrocones, integral holes, flow nozzles and Venturi tubes. With the exception of Metrocones, these differential pressure measuring elements require the installation of flow rectifiers to provide an adequate flow profile, thus guaranteeing perfect measurement.",
      },
      {
        id: "metroval-13",
        title: "Mobile Dosing and Filling Systems",
        img: "https://via.placeholder.com/400x400",
        subtitle: "",
        icon: "line-icon-Flick",
        btnLink: "/produtos/detalhe",
        btnTitle: "See details",
        description:
          "These customized systems use the principle of volumetric or mass meters using the Coriolis effect. Associated with a pre-determination system and valves, the product offers excellent performance, with a maximum error of 0.5% of the measured value in the range in which it is calibrated. Furthermore, the ease of mobility of the system allows greater agility in the dosing and filling operation and with exact pre-set values. The systems consist of a pump, filter, volumetric or mass flow meter, piping, dosing valves and HMI for Batch Preset, in addition to having a robust structure and easy operation. We develop dosing systems composed of volumetric or mass meters, to be used in dosing liquid raw materials. We also develop mobile or fixed filling systems, which allow the packaging of final products in the most diverse containers, such as cans, drums and containers. This system is widely used for dosing and/or filling paints and varnishes.",
      },
      {
        id: "metroval-14",
        title: "Integrated Systems for Flow Measurement",
        img: "https://via.placeholder.com/400x400",
        subtitle: "",
        icon: "line-icon-Flick",
        btnLink: "/produtos/detalhe",
        btnTitle: "See details",
        description:
          "Metroval is a qualified supplier not only at Petrobras, but also at many other operators. Our Measurement Systems enable full control of fiscal, custody transfer, appropriation and operational measurement, fully meeting the technical, legal and metrological requirements of the oil and gas industry.Systems are designed, built and tested in our facilities. Our metrological infrastructure allows you to separately test and validate measuring instruments, such as flow meters, densimeters, tasters, samplers and BSW analyzers. Additionally, our accredited flow laboratory is qualified to carry out initial checks on small and large systems. Metroval was the first company to conclude in 2006 the largest flow measurement contract in national history: the complete adaptation of 14 platforms for oil, gas and water measurement systems for four Petrobras assets in the Campos Basin.With more than 500 systems installed in Brazil and around the world, Metroval's integrated flow measurement systems are supplied in accordance with the technical standards and technical regulations of ANP and Inmetro.",
      },
      {
        id: "metroval-15",
        title: "Mobile Dosing and Filling Systems",
        img: "https://via.placeholder.com/400x400",
        subtitle: "",
        icon: "line-icon-Flick",
        btnLink: "/produtos/detalhe",
        btnTitle: "See details",
        description: "Mobile Dosing and Filling Systems",
      },
    ],
  },
  {
    img: "/assets/img/inov8.png",
    title: "Oil in Water Analysers",
    slug: "inov8",
    description:
      "Inov8 Systems was created and launched with a clear vision to research, develop and produce the world's most innovative instrumentation and control systems, to address unresolved challenges.Oil in Water Analyser could be considered the best available technology in this segment. It is manufactured by INOV8, and among his main characteristics we would emphasize:LASER-INDUCED FLUORESCENCE - Accurate and robust monitoring of oil in water concentration.RAPID WAVE ULTRASONIC CLEANING - Optimize cleaning and eliminate damage to the window.LOW MAINTENANCE - No maintenance outside annual service visit. ",
    products: [
      {
        id: "inov8-1",
        title: "Intelligent Control - Oil in Water Probe",
        img: "img/products/inov8/product-1.png",
        subtitle: "EXP Controller",
        icon: "line-icon-Flick",
        btnLink: "/produtos/detalhe",
        btnTitle: "See details",
        description: `Features 
        New rapid wave ultrasonic self cleaning 
         Choice of standard or high-resolution spectrometry feature 
         Low power DC voltage requirements 
         Multiple Communication options for Management Capabilities 
         Probe with integrated control - zero footprint 
         Adjustable flange mount for probe insertion depth 
         Integral, configurable colour display 
         Less than 25KG weight 
         316SS Exd Hazardous ATEX, IECEx and C1D1 Certification 
        
        Benefits 
         Low cost of ownership with no regular maintenance, no routine calibration and no added chemicals 
         Simple to use interface for set-up and control
         Lightweight with easy access to instrument without removing from process. 
         Meets hand carry requirements for choppers
         Minimal installation requirements (24VDC, Exd)
         Remote Monitoring and Automatic Process Control
         Continuous real-time measurements; <1 sec response to process changes 
         24 month standard warranty
        `,
      },
      {
        id: "inov8-2",
        title: "Mobile Dosing and Filling Systems",
        img: "img/products/inov8/product-2.png",
        subtitle: "EXS Controller",
        icon: "line-icon-Flick",
        btnLink: "/produtos/detalhe",
        btnTitle: "See details",
        description: `
        Features 
        New rapid wave ultrasonic self cleaning 
        Choice of standard or high-resolution spectrometry feature 
        Low power DC voltage requirements 
        Multiple Communication options for Management Capabilities 
        Small footprint only requiring front access for easy maintenance and installation 
        Large user configurable colour display 
        Less than 25KG weight 
        316SS Exd Hazardous ATEX, IECEx and C1D1 Certification 

        Benefits 
        Low cost of ownership with no regular maintenance, no routine calibration and no added chemicals 
        Simple to use interface for set-up and control 
        Wall, rack or stand mounting options 
        Meets hand carry requirements for choppers 
        Minimal installation requirements (24VDC, Exd) 
        Remote Monitoring and Automatic Process Control 
        Continuous real-time measurements; <1 sec response time to process changes 
        24 month standard warranty 
        `,
      },
      {
        id: "inov8-3",
        title: "General Purpose Oil in Water - Measurement and Control",
        img: "img/products/inov8/product-3.png",
        subtitle: "GPP Controller",
        icon: "line-icon-Flick",
        btnLink: "/produtos/detalhe",
        btnTitle: "See details",
        description: `Features
        Unique to the industry - rapid wave ultrasonic self cleaning 
        Low power DC voltage requirements 
        Multiple Communication options for Management Capabilities 
        Probe with integrated Control 
        Adjustable flange mount for probe insertion depth 
        Integrated Colour Display 
        Less than 12KG weight - easy to transport 
        
        Benefits
        • Low cost of ownership with no regular maintenance, no routine calibration
        • Simple to use intuitive interface for set-up and control
        • Lightweight and easy access to instrument without removing from the process 
        • Minimal installation requirements (24VDC)
        • Remote Monitoring and Automatic Process Control
        • Continuous real-time measurements; <1 sec response time to process changes 
        • 24 months standard warranty on parts 
        `,
      },
      {
        id: "inov8-4",
        title:
          "Intelligent Control - Oil in Water Side Stream Customer inspired innovation",
          img: "img/products/inov8/product-4.png",
        subtitle: "GPS Controller",
        icon: "line-icon-Flick",
        btnLink: "/produtos/detalhe",
        btnTitle: "See details",
        description: `Features 
        New rapid wave ultrasonic self cleaning 
        Choice of standard or high-resolution spectrometry feature 
        Low power DC voltage requirements 
        Multiple Communication options for Management 
        Capabilities 
        Small footprint only requiring front access for easy 
        maintenance and installation 
        Large user configurable colour display 
        Less than 25KG weight 
        
         Benefits 
        Low cost of ownership with no regular maintenance, no routine calibration and no added chemicals 
        Simple to use interface for set-up and control 
        Wall, rack or stand mounting options 
        Minimal installation requirements (24VDC) 
        Remote Monitoring and Automatic Process Control 
        Continuous real-time measurements; <1 sec response time to process changes 
        24 month standard warranty 
        `,
      },
      {
        id: "inov8-5",
        title: "HT405P / HT280P Oil in Water Measurement",
        img: "img/products/inov8/product-5.png",
        subtitle: "HT - Portable Meter",
        icon: "line-icon-Flick",
        btnLink: "/produtos/detalhe",
        btnTitle: "See details",
        description: `Features 
        All-in-one Hand Held Portable & Benchtop Device
        • Built-in rechargeable battery
        • Integrated 10.1” Touchscreen Colour Display
        • Linux based PC operation
        • Simple Intuitive Interface for set—up and control
        • 1.8kg weight
        
        Benefits
        • Flexible, Durable Probe for Measurement
        • Results can be correlated to official lab methods (USEPA 1664B and ISO 9377-1)
        • Full real time Spectrometry for measurement and analysis
        • Continuous real-time measurements with Immediate results
        • 12 months standard warranty
        • Hydrocarbons measured: Crude Oil, Gas Condensate, PAH’s, Phenol’s, lubricants
        `,
      },
    ],
  },
  {
    img: "/assets/img/gsi.png",
    bgColor: "#fff",
    borderColor: "#262b35",
    title: "Gauging Systems",
    slug: "gsi",
    description: `GSI manufactures volume, liquid level, and mass tank gauging equipment and systems for the petroleum, petrochemical, power, food & beverage, pharmaceutical, marine, pulp & paper, and water industries. Providing customers with the means for measurement of volume by Hybrid and Hydrostatic Methods to within Custody Transfer Accuracy (Loss Control) standards in accordance with API, ISO, GOST, and OIML. GSI’s offerings range from turn-key enterprise solutions, to supply of software, instruments, and spare parts. Services are also available, including integration expertise, field service, and bench repair for both GSI’s and competitors’ equipment worldwide.
    In 1995, GSI became the first tank gauging software developer to qualify as a “Microsoft Certified Solution Provider”. Currently, we are a “Microsoft Certified Partner”, developing tank gauging software programs—and other Windows applications—that are expandable from a single user interface to enterprise based solutions, and designed for the .NET Framework.
     In terms of “Inventory Loss Control”, we also can provide the most effective solution for Bulk Liquid Storage Tanks.
    The solution/equipment is manufactured by GSI (Gauging System Inc.), and it includes:
    Tank Gauging (Product Quantity & Quality);
    Overfill & Rupture Protection;
    Leak Detection (Unauthorized Movement);
    Vapour Monitoring (Emissions, PRV, or Blanket Monitoring),
    Tank Bottom or Roof Movement Indication, etc. Redundant and Tridundant solutions.
    `,
    products: [
      {
        id: "gsi-1",
        title:
          "MTG 3018 Redundant - MTG 3012 Multi-function Tank Gauge & MOP 3006 Overfill and Rupture Protection",
        img: "https://via.placeholder.com/400x400",
        subtitle: "",
        icon: "line-icon-Flick",
        btnLink: "/produtos/detalhe",
        btnTitle: "See details",
        description: `The MTG 3018 is the combination of MTG 3012 “Multi-function Tank Gauge and MOP 3006 “Multi-function Overfill Probe”, two completely independent gauges in one physical probe assembly. Each gauge has its own transmitter card and sensor array within the shared gauge housing, thus, only one tank top flange opening is required for mounting “Two” gauges. Each gauge uses independent signal and power sources within the system to maintain complete independence. Exceeds API 2350 Overfill Protection requirements.
        `,
      },
      {
        id: "gsi-2",
        title: "MTG 3014 Multi-function Tank Gauge with Critical Redundancy",
        img: "https://via.placeholder.com/400x400",
        subtitle: "",
        icon: "line-icon-Flick",
        btnLink: "/produtos/detalhe",
        btnTitle: "See details",
        description: `The MTG 3014 is the MTG 3012 with two (2) additional “Critical sensors” or redundant critical sensors.  The two critical sensors are the Vapor Sensor & Bottom Sensor. These two sensors are necessary to maintain a working MTG.  If any of the middle sensors (one or more) fail, the MTG will put them into maintenance mode with a diagnostic registered set, and the MTG will continue to operate.
        In order to minimize the Mean Time to Repair or eliminate total MTG failure on critical application tanks, remote tanks, tanks that are harder to get to or support, we offer the redundant critical sensors. They are added within the MTG 3012 (configured either hot or cold) for redundant Vapor and Bottom sensors.
        NOTE: Other types of sensors for use within MTG configurations are available. Alternative probe materials are also available. Consult GSI with your application and tank needs.
        `,
      },
      {
        id: "gsi-3",
        title: "2570 Mechanical Tank Gauge",
        img: "https://via.placeholder.com/400x400",
        subtitle: "",
        icon: "line-icon-Flick",
        btnLink: "/produtos/detalhe",
        btnTitle: "See details",
        description: `The GSI 2570 Mechanical Tank Gauge is a mechanically actuated, float and tape instrument designed to provide continuous level measurement in tanks, vessels, vats, or any other container for liquid storage. Also referred to as Automatic Tank Gauge (ATG), Float and Tape Tank Gauge, Float Type Level Gauge.
        The latest modification (Patent Pending) to the GSI-2570 is the removable checker gland. It is, no longer required to remove the bolts and back cover, cassette, TSSA, and tape to change the checker assembly, checker or hair spring. This new method will save hours of maintenance.
        Float & Tape Tank Gauge Applications

        Petroleum Tanks
        Airport Tanks
        Chemical Tanks
        Waste Water Treatment Tanks
        Lubricant Oil Tanks

        SPECIFICATIONS

        Mounting:
        Pipe & Bracket Mounting System
        Pressure:
        15 - 25 psi
        Temperature Range:
        -40 °F to 185 °F (-40 °C to 85 °C)
        Weight (head only):
        28 lbs. (12.7 kg)
        `,
      },
    ],
  },
  // {
  //   img: "/assets/img/apple.png",
  //   title: "Valtek",
  //   slug: "valtek",
  //   description: `ValtekSul is a privately owned company, established in Brazil as a leading Control Valves Manufacturer. During the last 40 years ValtekSul has successfully supplied control valves to the local and international On-shore and Off-shore markets.
  //   ValtekSul is engineering & manufacturing control valves in Brazil under strict international standards. Our industrial infrastructure has an installed capacity to produce around 2,000 control valves per year, as well as to execute off-shore field services to fully support our considerable installed base.
  //   `,
  //   products: [
  //     {
  //       title: "GXL-Chronos",
  //       img: "https://via.placeholder.com/400x400",
  //       subtitle: "Globe Control Valve",
  //       icon: "line-icon-Flick",
  //     },
  //     {
  //       title: "Control valve GLS",
  //       img: "https://via.placeholder.com/400x400",
  //       subtitle: "Globe Control Valve",
  //       icon: "line-icon-Flick",
  //     },
  //     {
  //       title: "Control Valve High Pressure",
  //       img: "https://via.placeholder.com/400x400",
  //       subtitle: "Globe Control Valve",
  //       icon: "line-icon-Flick",
  //     },
  //     {
  //       title: "Control Valve Bar Stock",
  //       img: "https://via.placeholder.com/400x400",
  //       subtitle: "Globe Control Valve",
  //       icon: "line-icon-Flick",
  //     },
  //     {
  //       title: "Control Valve Cryogenic applications",
  //       img: "https://via.placeholder.com/400x400",
  //       subtitle: "Globe Control Valve",
  //       icon: "line-icon-Flick",
  //     },
  //     {
  //       title: "Control Valve “Y” Patern",
  //       img: "https://via.placeholder.com/400x400",
  //       subtitle: "Globe Control Valve",
  //       icon: "line-icon-Flick",
  //     },
  //     {
  //       title: "Control Valve Bellows-Pac",
  //       img: "https://via.placeholder.com/400x400",
  //       subtitle: "Globe Control Valve",
  //       icon: "line-icon-Flick",
  //     },
  //     {
  //       title: "Short Body Eccentric Plug",
  //       img: "https://via.placeholder.com/400x400",
  //       subtitle: "Rotary Control Vales",
  //       icon: "line-icon-Flick",
  //     },
  //     {
  //       title: "Long Body Eccentric Plug",
  //       img: "https://via.placeholder.com/400x400",
  //       subtitle: "Rotary Control Vales",
  //       icon: "line-icon-Flick",
  //     },
  //     {
  //       title: "Bi-eccentric disc",
  //       img: "https://via.placeholder.com/400x400",
  //       subtitle: "Rotary Control Vales",
  //       icon: "line-icon-Flick",
  //     },
  //     {
  //       title: "Bi-eccentric disc High Pressure",
  //       img: "https://via.placeholder.com/400x400",
  //       subtitle: "Rotary Control Vales",
  //       icon: "line-icon-Flick",
  //     }, {
  //       title: "Segmented Ball",
  //       img: "https://via.placeholder.com/400x400",
  //       subtitle: "Rotary Control Vales",
  //       icon: "line-icon-Flick",
  //     },
  //     {
  //       title: "Special Trim Alpha Low and Medium Cavitation intensity",
  //       img: "https://via.placeholder.com/400x400",
  //       subtitle: "Special Trim",
  //       icon: "line-icon-Flick",
  //     },
  //     {
  //       title: "Special Trim Beta Noise level reduction",
  //       img: "https://via.placeholder.com/400x400",
  //       subtitle: "Special Trim",
  //       icon: "line-icon-Flick",
  //     },
  //     {
  //       title: "Attenuator Plate Beta Noise level reduction",
  //       img: "https://via.placeholder.com/400x400",
  //       subtitle: "Special Trim",
  //       icon: "line-icon-Flick",
  //     },
  //     {
  //       title: "Special Trim Delta Cavitation in liquids and gases",
  //       img: "https://via.placeholder.com/400x400",
  //       subtitle: "Special Trim",
  //       icon: "line-icon-Flick",
  //     },
  //     {
  //       title: "Special Trim Gamma cavitation in liquids",
  //       img: "https://via.placeholder.com/400x400",
  //       subtitle: "Special Trim",
  //       icon: "line-icon-Flick",
  //     },

  //   ],
  // },
];
