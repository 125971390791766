import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { Link } from "react-router-dom"

const SocialIconsData = [
  {
    color: "#828282",
    link: "https://linkedin.com/company/sparktrol",
    icon: "fab fa-linkedin"
  }
]

const Footer = (props) => {
  return (
    <footer className="py-[30px] border-t bg-darkslateblue">
      <Container>
        <Row className="items-center justify-content-between">
          <Col md={3} className="text-center md:text-start sm:mb-[8px] flex sm:justify-center">
            <Link aria-label="footer home link" to="/" className="flex justify-start">
              <img width={111} height={36} className="max-h-[36px]" src="/assets/img/logo-small.jpg" alt="" />
            </Link>
          </Col>
          <Col md={6} className="text-end sm:mb-[20px]">
            <p className="text-white">© {new Date().getFullYear()} Sparktrol</p>
          </Col>
          {/* <Col md={3}>
            <SocialIcons theme="social-icon-style-01" className="block text-right sm:text-center" iconColor="light" size="lg" data={SocialIconsData} />
          </Col> */}
        </Row>
      </Container>
    </footer>
  )
}

export default Footer