import { Formik, Form } from "formik"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { Input, TextArea } from "../../Components/Form/Form"
import Buttons from "../../Components/Button/Buttons"
import { AnimatePresence, m } from "framer-motion"
import MessageBox from "../../Components/MessageBox/MessageBox"
import { resetForm, sendEmail } from "../../Functions/Utilities";
import { ContactFormStyle02Schema } from "../../Components/Form/FormSchema"
import { fadeIn } from '../../Functions/GlobalAnimations';

const ContactForm = (props) => {
  return (
    <m.section className="py-[80px] bg-darkslateblue mt-[60px] xs:mt-[30px] lg:py-[120px] md:py-[65px] sm:py-[50px] xs:py-[40px]" {...fadeIn}>
      <Container>
        <Row>
          <Col className='mb-[15px]'>
            <h5 className="font-serif text-white text-center font-medium mb-[15px] lg:mb-[15px]">Contact</h5>
          </Col>
        </Row>
        <Row className="justify-center">
          <Col xl={5} lg={10} md={10} className="xs:px-[15px]">
            <Formik
              initialValues={{ name: '', email: '', phone: '' }}
              validationSchema={ContactFormStyle02Schema}
              onSubmit={async (values, actions) => {
                actions.setSubmitting(true)
                const response = await sendEmail(values)
                response.status === "success" && resetForm(actions)
              }}
            >
              {({ isSubmitting, status }) => (
                <Form>
                  <Input showErrorMsg={false} type="text" name="name" labelClass="mb-[15px]" className="pt-[20px] pr-[36px] pb-[23px] w-full bg-transparent text-[#828282] border-b border-solid border-[#ffffff33] text-[16px]" placeholder="Name" />
                  <Input showErrorMsg={false} type="email" name="email" labelClass="mb-[15px]" className="pt-[20px] pr-[36px] pb-[23px] w-full bg-transparent text-[#828282] border-b border-solid border-[#ffffff33] text-[16px]" placeholder="E-mail" />
                  <Input showErrorMsg={false} type="tel" name="phone" labelClass="mb-[15px]" className="pt-[20px] pr-[36px] pb-[23px] w-full bg-transparent text-[#828282] border-b border-solid border-[#ffffff33] text-[16px]" placeholder="Phone" />
                  <TextArea className="mb-[25px] py-[16px] pr-[36px] w-full bg-transparent text-[#828282] border-b border-solid border-[#ffffff33] text-[16px] resize-none" rows="6" name="comment" placeholder="How can we help you?"></TextArea>
                  <Buttons ariaLabel="form button" type="submit" className={`tracking-[0.5px] rounded-none font-medium uppercase${isSubmitting ? " loading" : ""}`} themeColor={["#d8dae6", "#80676b"]} size="sm" color="#fff" title="Send message" />
                  <AnimatePresence>
                    {status && <m.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}><MessageBox className="mt-[20px] py-[10px]" theme="message-box01" variant="success" message="Message successfully sent!" /></m.div>}
                  </AnimatePresence>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </m.section>
  )
}

export default ContactForm