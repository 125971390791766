import React, { memo } from 'react'

// Libraries
import { Row } from 'react-bootstrap'
import { PropTypes } from "prop-types";
import { m } from "framer-motion"

// Data
// import { serviceData1 } from './ServicesData'

// css
import "../../../Assets/scss/components/_services.scss"

const Brands = (props) => {
    return (
        <Row className={props.grid}>
            {
                props.data.map((item, i) => {
                    return (
                        <m.div key={i} className={`col flex flex-col items-center content-center px-[15px]${props.className ? ` ${props.className}` : ""}`} {...{ ...props.animation, transition: { delay: i * props.animationDelay } }}>
                            <div className={props.theme}>
                                <div className="img-wrapper">
                                    <a className={`${item.bgColor ?? "bg-[#262b35]"}`} aria-label="brand" href={item.slug ? `/${item.slug}/products` : "#"}>
                                        {
                                            item.img ?
                                                <img className="align-middle" title={item.title} src={item.img} /> :
                                                ""}
                                    </a>
                                </div>
                            </div>
                            <span className="font-bold">{item.title}</span>
                        </m.div>
                    )
                })
            }
        </Row>
    )
}

Brands.defaultProps = {
    data: [],
    theme: "service-style-06",
    animationDelay: 0.2
}

Brands.propTypes = {
    grid: PropTypes.string,
    theme: PropTypes.string,
    animationDelay: PropTypes.number,
    animation: PropTypes.object,
    data: PropTypes.arrayOf(
        PropTypes.exact({
            icon: PropTypes.string,
            title: PropTypes.string,
            slug: PropTypes.string,
            img: PropTypes.string,
            content: PropTypes.string,
            link: PropTypes.string,
            linkTitle: PropTypes.string,
        })
    ),
}


export default memo(Brands)