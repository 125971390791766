import React, { memo } from 'react'

// Libraries
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { PropTypes } from "prop-types";

// Components
import SocialIcons from '../SocialIcon/SocialIcons';
import FooterMenu, { Footer } from './Footer';
import StaticInstagram from '../Instagram/StaticInstagram';

// Data
import FooterData from './FooterData';

const iconData = [
    {
        color: "#828282",
        link: "https://www.facebook.com/",
        icon: "fab fa-facebook-f"
    },
    {
        color: "#828282",
        link: "https://dribbble.com/",
        icon: "fab fa-dribbble"
    },
    {
        color: "#828282",
        link: "https://twitter.com/",
        icon: "fab fa-twitter"
    },
    {
        color: "#828282",
        link: "https://www.instagram.com/",
        icon: "fab fa-instagram"
    },
]

const FooterStyle01 = (props) => {
    return (
        <Footer theme={props.theme} className={`${props.className ? ` ${props.className}` : ""}`}>
            <div className="py-[3%] lg:py-[25px] md:py-[35px]">
                <Container>
                    <Row className='justify-content-center'>
                        <Col lg={3} sm={6} className="xs:mb-[25px]">
                            <span className="mb-[20px] font-serif block font-medium text-themecolor xs:mb-[10px]">Get in touch</span>
                            <p className="w-[85%] mb-[15px]">Kuala Lumpur - Malaysia</p>
                            <div><i className="feather-phone-call text-sm mr-[10px] text-themecolor"></i>+60 10 222 5471</div>
                            <div><i className="feather-mail text-sm mr-[10px] text-themecolor"></i><a aria-label="mail" href="mailTo:nico.menghi@sparktrol.com">nico.menghi@sparktrol.com</a></div>
                        </Col>
                        <Col lg={3} sm={6}>
                            {/* <span className="mb-[20px] font-medium font-serif text-themecolor block xs:mb-[20px]">Follow us on Instagram</span> */}
                            <div className="w-full inline-block">
                                <a aria-label="link" rel="noreferrer" href="https://linkedin.com/company/sparktrol" target="_blank" className="flex justify-start text-slateblue font-medium text-xs font-serif uppercase mt-[20px] items-center hover:text-white">
                                    <i className="fab fa-linkedin text-lg mr-[10px]"></i>
                                    <span className="inline-block hover:text-white">Follow LinkedIn</span>
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Footer>
    )
}

FooterStyle01.defaultProps = {
    data: FooterData,
    className: "bg-darkgray text-[#828282]",
    logo: "/assets/img/webp/logo-white.webp",
    theme: "light"
}

FooterStyle01.propTypes = {
    className: PropTypes.string,
    logo: PropTypes.string,
}

export default memo(FooterStyle01)
