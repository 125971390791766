import React, { useEffect, useState } from 'react'

// Libraries
import { Col, Container, Row } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'

import Lists from '../../../Components/Lists/Lists';
import { fadeIn } from '../../../Functions/GlobalAnimations';

import { PropTypes } from "prop-types";
import { brands } from '../data';

const ListData01 = [
  {
    icon: "fas fa-check",
    content: "Advanced customization theme options",
  },
  {
    icon: "fas fa-check",
    content: "Manage and share your workspace",
  },
  {
    icon: "fas fa-check",
    content: "Powerful theme for creatives designer",
  },
  {
    icon: "fas fa-check",
    content: "Browse amazing popular social features",
  },
]

const SocialIconsData = [
  {
    color: "#3b5998",
    link: "https://www.facebook.com/",
    icon: "fab fa-facebook-f"
  },
  {
    color: "#00aced",
    link: "https://twitter.com/",
    icon: "fab fa-twitter"
  },
  {
    color: "#0077b5",
    link: "https://www.linkedin.com/",
    icon: "fab fa-linkedin-in"
  },
  {
    color: "#ff0084",
    link: "https://www.pinterest.com/",
    icon: "fab fa-pinterest-p"
  }
]

const ListData = [
  {
    content: "Made from soft yet durable 100% organic cotton twill"
  },
  {
    content: "Front and back yoke seams allow a full range of motion"
  },
  {
    content: "Comfortable nylon-bound elastic cuffs seal in warmth"
  },
  {
    content: "Hem adjusts by pulling cord in handwarmer pockets"
  },
  {
    content: "Interior storm flap and zipper garage at chin for comfort"
  }
]

const ProductDetail = (props) => {

  const { company: slug, id } = useParams()

  const [selectedBrand, setSelectedBrand] = useState([])
  const [product, setProduct] = useState({})

  import("../../../Functions/Utilities").then(module => {
    module.InputField(1);
  })

  useEffect(() => {
    const brand = brands.filter((item) => item.slug === slug)[0]
    const product = brand.products.filter((item) => item.id === id)[0]
    setSelectedBrand(() => brand)
    console.log(selectedBrand)
    setProduct(product)
  }, [slug, id])

  return (
    <div className="single-product" style={props.style}>
      <section className="bg-darkgray py-[20px]">
        <Container>
          <Row className="items-center justify-between mb-0">
            <Col xl={8} lg={6} className="breadcrumb justify-start  font-serif md:mt-[10px] mb-0 md:justify-center">
              <ul className="xs-text-center">
                <li><Link aria-label="breadcrumb" to="/" className="hover:text-white">Home</Link></li>
                <li><Link aria-label="breadcrumb" to={`/${selectedBrand.slug}/products`} className="hover:text-white">{selectedBrand.title}</Link></li>
              </ul>
            </Col>
            {/* <Col xl={4} lg={6} className="breadcrumb justify-end text-xs font-serif md:mt-[10px] mb-0 md:justify-center">

            </Col> */}
          </Row>
        </Container>
      </section>
      <section>
        <Container className="h-full relative">
          <Row className="row items-stretch justify-center h-[200px] md:h-[275px] sm:h-[250px]">
            <Col xl={8} lg={8} md={8} xs={12} className="text-center flex justify-center flex-col">
              <h1 className="text-darkgray mb-[0px] font-serif font-medium -tracking-[1px] xs:mb-[0px]">{product.title}</h1>
            </Col>
          </Row>
          <Row className="items-center justify-center">
            <Col lg={6} xl={{ offset: 1 }}>
              <img loading="lazy" width="400" height="400" src={`/assets/${product.img}`} alt="Product description" />
            </Col>
            {product.description ? <Col xl={5} lg={6} className="md:mb-[50px]">
              <p className="mb-[25px]">{product.description}</p>
              {/* <Lists theme="list-style-04" data={ListData} animation={fadeIn} /> */}
            </Col> : ""}

          </Row>
          <Row className="items-center">
            <Col>
              <table className="w-full">
                <tbody className="xs:flex xs:flex-col xs:w-[100%]">
                  <tr className="border-b border-[#e8e8e8] py-[10px] px-[15px] xs:flex xs:flex-col xs:w-[100%]">
                    <th className="text-darkgray font-medium w-[250px] px-[15px]">Sizes</th>
                    <td className="py-[10px] px-[15px]">½” - 10”</td>
                  </tr>
                  <tr className="bg-lightgray border-b border-[#e8e8e8] py-[10px] px-[15px] xs:flex xs:flex-col xs:w-[100%]">
                    <th className="text-darkgray font-medium w-[250px] px-[15px] xs:w-[100%]">Pressure classes</th>
                    <td className="py-[10px] px-[15px]">
                      <Lists theme="list-style-01" className="" data={ListData01} animation={fadeIn} />
                    </td>
                  </tr>
                  <tr className="border-b border-[#e8e8e8] ">
                    <th className="text-darkgray font-medium w-[250px] px-[15px]">Main features and options</th>
                    <td className="py-[10px] px-[15px]">Sports, Formal</td>
                  </tr>
                  <tr className="bg-lightgray border-b border-[#e8e8e8] ">
                    <th className="text-darkgray font-medium w-[250px] px-[15px]">Additional feature</th>
                    <td className="py-[10px] px-[15px]">Additional feature specification</td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Section Start */}
      {/* <section className="border-mediumgray pt-0 py-[50px] lg:py-[40px] md:py-[35px] sm:py-[20px]">
        <Container fluid>
          <Row>

          </Row>
        </Container>
      </section> */}
      {/* Section End */}
    </div>
  )
}

ProductDetail.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  specification: PropTypes.string
}

export default ProductDetail